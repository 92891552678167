ion-content {
  --background: none !important;
  background-image: url('../images/bg_7.jpg') !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

ion-toolbar {
  height: 100% !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  display: flex;
}

ion-header {
  height: 80px !important;
  display: flex !important;
  align-items: center !important;
}

.title-page {
  position: absolute !important;
  height: 100% !important;
  top: 0 !important;
  left: 0 !important;
  text-align: center !important;
  width: 100% !important;
}

.footer-page {
  color: #7f8c8d !important;
  font-size: 10px !important;
  margin: 8px 15px !important;
  text-align: end !important;
}

.network-icon {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  position: absolute;
  width: 100%;
  text-align: end;
  padding: 2px 10px;
  top: 0;
  left: 0;
  font-size: 25px;
  color: var(--ion-color-primary);
}

.network-offline {
  color: #57606f !important
}

.eye-icon {
  color: var(--ion-color-primary);
  cursor: pointer;
  margin-top: 24px !important;
}

.card-login {
  display: block !important;
  margin: auto !important;
  max-width: 410px !important;
  padding: 40px 0 20px !important;
  opacity: 1 !important;
}

.card-content-data {
  display: block !important;
  margin: auto !important;
  max-width: 410px !important;
  padding: 5px 0 15px !important;
  opacity: 1 !important;
}


.content-image-title {
  align-items: center !important;
  display: flex !important;
  height: 85px !important;
  min-height: 85px !important;
  justify-content: center !important;
  --min-height: 85px !important;
}

.state-title {
  position: absolute !important;
  bottom: -10px !important;
  font-weight: 900 !important;
  font-size: 11.5px !important;
  color: var(--ion-color-primary) !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.image-title {
  height: 110px !important;
  margin-left: -50px !important;
  margin-right: -10px !important;
}

.image-menu {
  display: block !important;
  height: 170px !important;
  margin: -30px auto;
}

.menu-user {
  display: block !important;
  margin: 5px auto !important;
  text-align: center !important;
}

.item-card {
  border: 1px solid var(--ion-color-step-150, #d7d8da);
  border-radius: 18px;
}

.item-card-data {
  border: 1px solid var(--ion-color-step-150, #d7d8da);
  border-radius: 18px;
  box-shadow: initial !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.btn-add {
  height: 35px !important;
  margin: 5px 10px 5px auto !important;
  max-width: 150px !important;
  width: 90% !important;
  font-size: 16px !important;
  box-shadow: 0px 10px 15px -15px rgb(0 0 0 / 58%) !important;
}

:host(.button-block) .button-native {
  border-radius: 25px !important;
}

.btn-opcion-fondos {
  height: 35px !important;
  margin: 10px auto !important;
  max-width: 150px !important;
  width: 90% !important;
  font-size: 16px !important;
  box-shadow: 0px 10px 15px -15px rgb(0 0 0 / 58%) !important;
}

.label-dia-fondos {
  color: #000000;
  display: block;
  font-size: 15px;
  font-weight: 500;
  margin: 5px 10px;
  text-align: center;
}

.list-rows {
  max-height: 450px !important;
  overflow-y: auto !important;
  padding-top: 0px !important;
  background: rgba(236,240,241, 0.5) !important;
}

.list-rows-fondos {
  max-height: 300px !important;
  overflow-y: auto !important;
  padding-top: 0px !important;
}

.back-button {
  margin: 5px 10px 5px auto !important;
  font-size: 18px !important;
  width: 50px !important;
  --padding-start: 0px !important;
  --padding-end: 0px !important;
}

.btn-guardar {
  height: 50px;
  margin: 30px auto;
  max-width: 360px;
  width: 90%;
  font-size: 18px !important;
  box-shadow: 0px 10px 15px -15px rgb(0 0 0 / 58%) !important;
}

.formulario-input {
  max-width: 360px;
  margin: 16px auto;
  width: 90%;

  --background: #ecf0f1 !important;
  --border-radius: 25px !important;
  --highlight-height: 0 !important;
  --border-width: 0.5px;
  --border-color: lightgray;
}

.formulario-input:focus {
  --background: red !important;
}

.formulario-input-buscar {
  max-width: 360px;
  margin: 12px auto;
  width: 90%;

  --background: #ecf0f1 !important;
  --border-radius: 25px !important;
  --highlight-height: 0 !important;
}

.form-spinner-progress {
  background: #ECF0F1 !important;
  position: absolute !important;
  right: 5px !important;
  top: 24px !important;
  z-index: 100 !important;
}

.content-icon-row {
  align-items: center !important;
  display: flex !important;
  height: 100% !important;
  justify-content: flex-end !important;
  position: absolute !important;
  right: 0 !important;
  top: 0 !important;
}

.icon-in-row {
  width: 27.3px !important;
  --padding-start: 0px !important;
  --padding-end: 0px !important;
}

.input-date {
  background: #ECF0F1 !important;
  border: 0 !important;
  color: #000000 !important;
  margin-top: 5px !important;
  width: -webkit-fill-available !important;
  outline: none !important;
}

.total-label {
  color: var(--ion-color-tertiary) !important;
  font-size: 22px !important;
  font-weight: 600 !important;
  margin-top: 25px !important;
  text-align: center !important;
}

.res-label {
  color: var(--ion-color-primary) !important;
  font-size: 22px !important;
  font-weight: 600 !important;
  margin-top: 25px !important;
  text-align: center !important;
}

.res-label-secondary {
  color: var(--ion-color-secondary) !important;
  font-size: 22px !important;
  font-weight: 600 !important;
  margin-top: 25px !important;
  text-align: center !important;
}

.saldo-total {
  color: var(--ion-color-tertiary) !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  margin: 25px auto -10px !important;
  text-align: center !important;
}

.search-text {
  margin: -15px 0px -5px 26px !important;
  display: block !important;
}

.modal-wrapper {
  max-width: 410px !important;
}

.menu-sandia {
  max-width: 260px !important;
}

.register-title {
  text-align: center !important;
  margin: 20px auto 5px !important;
  display: block !important;
  font-size: 22px !important;
  font-weight: 600 !important;
}

.button-multi-opciones {
  color: var(--ion-color-primary) !important;
  cursor: pointer !important;
  font-size: 18px !important;
  outline: none !important;
  position: absolute !important;
  right: -5px !important;
  top: 2px !important;
}

.button-multi-opciones-fondos {
  color: var(--ion-color-primary) !important;
  cursor: pointer !important;
  font-size: 20px !important;
  outline: none !important;
  position: absolute !important;
  right: 10px !important;
  top: 4px !important;
}

.icon-fondos-admin {
  cursor: inherit !important;
  padding-top: 10px !important;
}

.container-error {
  display: grid !important;
  justify-content: flex-start !important;
  margin: -14px auto 0 !important;
  max-width: 360px !important;
  position: relative !important;
  width: 90% !important;
}

.label-error {
  color: var(--ion-color-danger) !important;
  font-size: 10.5px !important;
  font-weight: 500 !important;
  margin: auto auto auto 0 !important;
}

.strong {
  font-weight: 900 !important;
}

.asterisk {
  color: var(--ion-color-danger) !important;
  font-weight: 900 !important;
}

.recovery-text {
  outline: none !important;
  text-align: end !important;
  width: 94% !important;
}

.hr-menu {
  background-color: var(--ion-color-step-150, #d7d8da) !important;
  margin: 0 !important;
}

.opcion-menu-select {
  align-items: center i !important;
  display: flex !important;
  cursor: pointer !important;
  height: 30px !important;
  padding: 18px 0 !important;
}

.opcion-menu-select-eliminar {
  align-items: center i !important;
  display: flex !important;
  cursor: pointer !important;
  height: 30px !important;
  --background: var(--ion-color-secondary) !important;
  color: #FFFFFF !important;
  padding: 18px 0 !important;
}

.item-info-cliente {
  align-items: center !important;
  display: flex !important;
  height: 30px !important;
}

.item-info-agricultor {
  align-items: center !important;
  display: flex !important;
  height: 30px !important;
}

.label-info-cliente {
  padding: 10px 0 !important;
}

.mermas-opcion-menu {
  --background: var(--ion-color-warning) !important;
  color: #000000 !important;
}

.otra-opcion-menu {
  --background: var(--ion-color-secondary) !important;
  color: #ffffff !important;
}

.otro-estado-opcion-menu {
  --background: var(--ion-color-secondary) !important;
  background: var(--ion-color-secondary) !important;
  --color: #ffffff !important;
  color: #ffffff !important;
}

.text-deposito-cobranza {
  display: block !important;
  font-size: 14px !important;
  margin: auto !important;
  text-align: center !important;
  text-decoration: underline !important;
}

.fecha-actual-button {
  font-size: 14px !important;
  max-height: 22px !important;
}

.colot-total-mermas {
  color: var(--ion-color-warning) !important;
  font-weight: 600 !important;
}

.mostrando-mermas {
  color: #92949c !important;
}

.loading-wrapper {
  border-radius: 12px !important;
}

.text-center {
  text-align: center !important;
}

@media only screen and (max-width: 991px) {

  .menu-sandia {
    max-width: 100% !important;
  }

}
